.App {
  /* text-align: center; */
  background-color: #282c34;
  font-family: 'Roboto', sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Content-header {
  background-color: #282c34;
  min-height: 100vh;
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.fill-height {
  height: calc(100vh - 100px);
}

.run {
  /* position:absolute; */
  /* top:100; */
  /* right:0; */
  z-index: 100;
}

canvas {
    display:block;
    /* border: 1px solid black; */
    margin-top:10px;
}

.body-home {
  --primary-color: #2c3e50;
  --accent-color: #3498db;
  --text-color: #333;
  --light-text: #666;
  --background: #f9f9f9;

    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    background: var(--background);
    color: var(--text-color);
    line-height: 1.6;
}

.header-home {
    /* background: var(--primary-color); */
    background-color: #282c34;
    padding: 120px 0 60px;
    text-align: center;
    /* background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI3MCIgaGVpZ2h0PSI3MCI+CiAgPHBhdGggZD0iTTAgMGw3MCA3MEg3MFYwSDBaIiBmaWxsPSIjMzQ0OTVlIiBmaWxsLW9wYWNpdHk9IjAuMSIvPgo8L3N2Zz4='); */
    background: #222 url('/public/header-pattern.svg');
}

.header-home h1 {
    margin: 40px;
    font-size: 4rem;
    font-weight: 700;
    color: white;
}

.header-home p {
    font-size: 1.4rem;
    color: rgba(255, 255, 255, 0.9);
    max-width: 600px;
    margin: 1rem auto;
}
section {
    padding: 60px 20px;
}

.section-content {
    max-width: 1200px;
    margin: 0 auto;
}

.platform-summary {
    background: #F8E020;
    border-bottom: 1px solid rgba(0,0,0,0.1);
}

.platform-summary p {
    max-width: 800px;
    margin: 0 auto 30px;  /* Added bottom margin */
    font-size: 1.2rem;
    color: var(--text-color);
    line-height: 1.8;
}

.features-section {
    background: white;
}

.features {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    margin-top: 40px;
}

.feature {
    /* text-align: center; */
    padding: 2rem;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
}

.feature:hover {
    transform: translateY(-5px);
}

.feature i {
    font-size: 2.5rem;
    /* color: var(--accent-color); */
    margin-bottom: 1rem;
}

.feature h3 {
    color: var(--primary-color);
    margin: 1rem 0;
}

.cta {
    text-align: center;
    margin-top: 10px;
    color: white;
}

.cta a {
    display: inline-block;
    padding: 15px 40px;
    /* background: var(--accent-color); */
    color: white;
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: 500;
    border-radius: 5px;
    transition: background 0.3s;
}

.cta a:hover {
    /* background: #2980b9; */
}

.brand2 {
  color: white;
  font-size: 1.8rem;
}

.logo-tag {
  position: relative;
  bottom: 30px;
  color: white;
  font-size: 1.4rem;
  right: -100px;
}
.logo-tag-nav {
  position: relative;
  bottom: -10px;
  color: white;
  font-size: 1.4rem;
}

.nodebot {
    margin: 0 auto;
    z-index: 2;
    opacity: 1;
    width: 150px;
    height: 150px;
    background-color: #F8E01E;
    position: relative;
    /* top: 40px */
}

.nodebot .icon {
    position: absolute;
    bottom: 10%;
    right: 10%;
    width: 80%;
    height: 80%
}

.nodebot .icon * {
    background: #2e2e2c
}

.nodebot .icon .bobble {
    width: 12.5%;
    height: 12%;
    border-radius: 100%;
    margin: 5% auto -3.8%
}

.nodebot .antenna {
    width: 6%;
    margin: 0 auto 4%;
    height: 16.5%;
    border-bottom-left-radius: 80% 30%;
    border-bottom-right-radius: 80% 30%
}

.nodebot .head {
    width: 100%;
    height: 48.5%;
    border-top-left-radius: 15% 25%;
    border-top-right-radius: 15% 25%;
    position: relative
}

.nodebot .head .eye {
    width: 18%;
    height: 33.5%;
    margin: 0 18.2%;
    border-radius: 100%;
    background-color: #F8E01E;
    display: inline-block;
    position: absolute;
    top: 26%;
    left: 0px;
    -webkit-animation: blink 5s 2s infinite;
    animation: blink 5s 2s infinite
}

.nodebot .eye.right {
    left: auto;
    right: 0
}

.nodebot .head .eye.blink {
    height: 6%;
    top: 36%;
    border-radius: 0;
    background-color: #000
}

.nodebot .neck {
    border-radius: 20px;
    width: 69%;
    height: 6%;
    margin: 3.6% auto 0
}

.nodebot .neck:last-child {
    width: 48%
}

@-webkit-keyframes blink {
    0%, 4%, 8%, 100% {
        height: 33.5%;
        border-radius: 100%;
        background-color: #F8E01E;
        top: 26%
    }

    2%, 6% {
        height: 6%;
        top: 36%;
        border-radius: 0;
        background-color: #000
    }
}

@keyframes blink {
    0%, 4%, 8%, 100% {
        height: 33.5%;
        border-radius: 100%;
        background-color: #F8E01E;
        top: 26%
    }

    2%, 6% {
        height: 6%;
        top: 36%;
        border-radius: 0;
        background-color: #000
    }
}

footer {
    text-align: center;
    padding: 20px;
    background: var(--primary-color);
    color: rgba(255, 255, 255, 0.7);
}

.hardware-support {
    background: white;
    border-top: 1px solid rgba(0,0,0,0.1);
}

.hardware-support h2 {
    color: var(--primary-color);
    margin-bottom: 40px;
}

.boards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 2rem;
    max-width: 1000px;
    margin: 0 auto;
}

.board {
    padding: 20px;
    background: #F8E020;
    border-radius: 8px;
    transition: transform 0.2s;
}

.board:hover {
    transform: translateY(-5px);
}

.board i {
    font-size: 2rem;
    /* color: var(--accent-color); */
    margin-bottom: 1rem;
}

.board h3 {
    margin: 10px 0;
    color: var(--primary-color);
}

.code-sample {
    background: #282c34;
    border-top: 1px solid rgba(255,255,255,0.1);
}

.code-sample h2 {
    color: white;
    margin-bottom: 40px;
}

.code-columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.setup-steps {
    /* text-align: left; */
    color: white;
    padding: 20px;
}

.setup-steps h3 {
    /* color: var(--accent-color); */
    color: #F8E020;
    margin-bottom: 1.5rem;
}

.command {
    background: #1e1e1e;
    padding: 0.75rem 1rem;
    border-radius: 4px;
    margin: 1rem 0;
    font-family: monospace;
    font-size: 0.9rem;
}

.sdk-instructions {
    text-align: left;
    color: white;
    padding: 20px;
    margin-bottom: 20px;
}

.sdk-instructions h3 {
    color: var(--accent-color);
    margin-bottom: 1rem;
}

.sdk-instructions p {
    margin-bottom: 0.5rem;
}

.code-container {
    text-align: left;
    background: #1e1e1e;
    border-radius: 8px;
    padding: 1px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
}

@media (max-width: 768px) {
    .code-columns {
        grid-template-columns: 1fr;
    }
}

pre[class*="language-"] {
    margin: 0;
    padding: 0;
    background: transparent;
}

.code-container .comment {
    color: #608b4e;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
